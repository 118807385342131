
import Home from "./pages/Home/Home";
import { useEffect, useState } from "react";
import WOW from "wow.js";
import HomeTwo from "./pages/Home/HomeTwo";
import HomeGivart from "./pages/Home/HomeGivart";
import Servicios from "./pages/Home/Servicios";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import { useLocation } from "react-router-dom";
import ContactOne from "./components/Contact/ContactOne";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, } from 'react-router-dom';

import Support from "./pages/Home/Support";

import About from "./pages/Home/About2";

import Pricing from "./pages/Home/Pricing";

import  {LangProvider}  from './context/lang/LangProvider'


function App() {
 
  ////// funcion del idioma ///
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  

  return (
    <> 
    <LangProvider>

      <Routes>
        {/* <Route path="/papi" element={<Home />} /> */}
        <Route path="/" element={<Servicios/>} />
        <Route path="/pricing" element={<Pricing/>} />       
        <Route path="/about" element={<About/>} />       
        {/* <Route path="/home" element={<HomeTwo />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog-details" element={<BlogDetailsPage />} /> */}
        <Route path="/support" element={<Support/>} />
        <Route path="*" element={<Navigate replace to="/" />} />
       
      </Routes>

      </LangProvider>


    </>
  );
}

export default App;
