import React from "react";
import About from "../../components/About/About";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartzService";
import videobg from "../../assets/videos/1.mp4"
import videohead from "../../assets/videos/2.mp4"
import videoblue from "../../assets/videos/4.mp4"
import videobody from "../../assets/videos/bg.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import useLang from "../../hooks/useLang";


const Support = () => {
  const {
    lang,
  } = useLang();
  return (
    <LayoutGivartz>
      <main className="fix" >
       
       <section className="rene-sensa">
       <section className="video-rene">
            <div className="flex-rene-led">
                    
                      <h1  className="Text-Big">        
                         
                          {lang.data.Support.Header.n01}
                            
                      </h1>

                    
              </div>
              <video 
                className="video-bg-rene"
                autoPlay={true}
                loop={true} 
                playsInline={true}
                muted={true}
              webkitplaysinline="true"
              tabIndex="-1" 
              aria-hidden="true">
                {/* <source  src={videobg} type="video/mp4"/>
                  <source  src={videobg} type="video/webm"/>
                <source src={videobg} type="video/ogg"/> */}
                <source  src={videohead} type="video/mp4"/>
                      <source  src={videohead} type="video/webm"/>
                    <source src={videohead} type="video/ogg"/>
                </video>
          </section>
       </section>
       <section  className="rene-sensa-04">
<div className="box-rene-design">


<h2 className="text-arena-rene">
        
        {lang.data.Support.Header.n02}
                  </h2>


  <div>
 
              </div>

            

    

    

  </div>


</section>


      

     
        
       {/* <Banner /> */}
        {/* <About />  */}
       
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo /> */}
        {/* <TeamTwo /> */}
        {/* <DownloadArea /> */}
        {/* <Faq /> */}
        <ContactTwo 
          lang={lang}
        />
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Support;
