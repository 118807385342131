import React from "react";
import About from "../../components/About/About";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUsTwo from "../../components/WhyChooseUs/WhyChooseUsTwo";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartzService";
import videobg from "../../assets/videos/1.mp4"
import videohead from "../../assets/videos/3.mp4"
import videoblue from "../../assets/videos/4.mp4"
import videobody from "../../assets/videos/bg.mp4"
import logoImage from "../../assets/img/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import picon from '../../assets/img/logo/PicoaiPickss.jpg';
import useLang from "../../hooks/useLang";


const About2 = () => {

  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();
  
  return (
    <LayoutGivartz>
      <main className="fix" >
       {/* <WhoWeAre /> */}
       {/* <section className="rene-sensa">
       <section className="video-rene">
<div className="flex-rene">
        
          <h1  className="Text-Big">        
          About
                 
          </h1>

          <h4>

   Pico Ai Picks
          </h4>
        
        
  </div>
  

  

<video 
  className="video-bg-rene"
  autoPlay={true}
  loop={true} 
playsInline="true"
 webkitplaysinline="true"
 tabIndex="-1" 
 aria-hidden="true">
  <source  src={videobg} type="video/mp4"/>
    <source  src={videobg} type="video/webm"/>
  <source src={videobg} type="video/ogg"/>



  </video>





          </section>

       </section>

       */}

       <section  className="rene-sensa-04">

        <div className="box-rene-design">
        <h2 className="text-arena-rene">
               
                {lang.data.About.Header.n01}
                          </h2>
                          <p className="text-arena-rene-p">
                          {lang.data.About.Header.n02}
                         
                          </p>
          <div>
          <div className="picobg">

                      
            <video 
              className="video-bg-brutal"
              autoPlay={true}
            loop={true} 
            playsInline={true}
            webkitplaysinline="true"
            tabIndex="-1" 
            aria-hidden="true">
              <source  src={videohead} type="video/mp4"/>
                <source  src={videohead} type="video/webm"/>
              <source src={videohead} type="video/ogg"/>



              </video>
            </div>
                      </div>

                    

            

            

          </div>
        

       </section>



       
       <section  className="rene-sensa-04">

        <div className="box-ricky-design">
        <h2 className="text-arena-rene">
        {lang.data.About.Header.n03}
                          </h2>



                          <img
                            src={picon}
                            alt="robot"
                            className="picoho"
                          
                          />
                        
        

                    

            

            

          </div>
        

       </section>

     
       
      

     
       
      

       
     
        
       {/* <Banner />
        <About /> 
        */}
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo />
        <TeamTwo />
        <DownloadArea /> */}
        <Faq />
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default About2;
