import React, { useContext } from 'react'

import LangContext from '../context/lang/LangProvider'

const useLang = () => {
    return useContext(LangContext)
}


export default useLang;
