import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/logo.svg";
import esflag from "../../../src/assets/img/logo/esflag.png";
import usflag from "../../../src/assets/img/logo/usflag.png";
import $ from "jquery";
import useLang from "../../hooks/useLang";

const HeaderPicoAi = () => {

  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();



  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div class="dropdown-btn"><span class="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      var mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);




  // scroll to element by id
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {

      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // active link switching
  const { hash, pathname } = useLocation();
  const isActiveLink = (id) => {
    return id == hash ? "active" : "";
  };

 
  return (
    <header id="header">
      <div
        id="sticky-header"
        className={cn(
          "menu-area menu-style-two transparent-header",
          stickyClass.header
        )}
      >
        <div className="container custom-container-three">
          <div className="row">
            <div className="col-12">
             
              <div className="menu-wrap">
                <nav className="menu-nav">
                 
                 

             
                      <div className="logo">
                    <Link to="/">
                      
                      <img className="logopico" src={logoImage} alt="" />
                    </Link>
                    <div className="lang-btn">
                        
                        <img 
                         className={` ${lang.status === 'EN' ? 'flag-active':'flag-flag'}`}
                         onClick={ActiveEN}
                         src={usflag} 
                         alt="" 
                         title="" />
                       
                     
                     
                     
                     <img  
                       className={` ${lang.status === 'ES' ? 'flag-active':'flag-flag'}`}
                       onClick={ActiveES}
                       src={esflag} 
                       alt="" title="" />

                     
                   </div>
                  </div>
                      <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li
                       className={cn(pathname == "/" && "active")}
                      >
                        <Link
                          to="/"
                          className="section-link"
                          onClick={() => handleClickScroll("#header")}
                        >
                          {lang.data.Extra.Header.n04}
                        </Link>
                      </li>
                      <li className={cn(pathname == "/pricing" && "active")}>
                        <Link
                          // to="/contact"
                          to="/pricing"
                          className="section-link"
                          onClick={() => handleClickScroll("#about")}
                        >
                             {lang.data.Extra.Header.n05}
                        </Link>
                      </li>
                      <li className={cn(pathname == "/support" && "active")}>
                            <Link
                               to="/support"  
                              className="section-link"
                            >
                               {lang.data.Extra.Header.n06}
                            </Link>
                      </li>
                      <li className={cn(pathname == "/about" && "active")}>
                            <Link
                              to="/about"  
                              className="section-link"
                            >
                               {lang.data.Extra.Header.n07}
                            </Link>
                      </li>
                      {/* <li className={isActiveLink("#faq")}>
                        <Link
                          to="#faq"
                          className="section-link"
                          onClick={() => handleClickScroll("faq")}
                        >
                          Contact
                        </Link>
                      </li>
                      */}
                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul>
                      {/* <li className="header-lang">
                        <span className="selected-lang">ENG</span>
                        <ul className="lang-list">
                          <li>
                            <Link to="#">IND</Link>
                          </li>
                          <li>
                            <Link to="#">BNG</Link>
                          </li>
                          <li>
                            <Link to="#">TUR</Link>
                          </li>
                          <li>
                            <Link to="#">CIN</Link>
                          </li>
                        </ul>
                      </li> */}
                      <div className="lang">

                    
                      <li className="header-btn">
                      <a 
                      className="btn"
                        target="_blank" rel="noopener"
                        href="https://sublaunch.co/picoaipickspro"
                        >
                
              
                {lang.data.Extra.Header.n03}


              </a> 

                        {/* <Link to="/" 
                          onClick={() => handleClickScroll("contact")}
                        className="btn btn-mood">
                        Login
                        </Link> */}
                      </li>

                      {/* <div className="lang-btn">
                        <button className={`${lang.status === 'EN'? 'active-btn':''}`}
                          onClick={ActiveEN}
                        >
                           {lang.data.Extra.Header.n01}
                          
                        </button>
                        <button className={`${lang.status === 'ES'? 'active-btn':''}`}
                          onClick={ActiveES}
                        >
                        
                          {lang.data.Extra.Header.n02}
                        </button>
                      </div> */}

                      </div>
                      
                    


                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn">
                    <i className="fas fa-times"></i>
                  </div>
                  <div className="nav-logo">
                    <Link to="/">
                      <img src={logoImage} alt="" title="" />
                    </Link>
                  </div>
                  
                  <div className="menu-outer">
                    
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--> */}
                  </div>
                  <div className="social-links">
                    
                    <ul className="clearfix lang-btn">
                      
                      {/* <li>
                        <Link to="#">
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube"></i>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                  <li className="header-btn d-grid">
                      <a 
                      className="btn center-btn"
                        target="_blank" rel="noopener"
                        href="https://sublaunch.co/picoaipickspro"
                        >
                
              
                {lang.data.Extra.Header.n03}

              </a> 
             

                       
                      </li>
                </nav>

              </div>
              <div className="menu-backdrop"></div>
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderPicoAi;
