import React from "react";
import shape01 from "../../assets/img/images/faq_shape01.png";
import shape02 from "../../assets/img/images/faq_shape02.png";
import shape03 from "../../assets/img/images/faq_shape03.png";
import FaqItem from "./FaqItem";
import useLang from "../../hooks/useLang";

const Faq = () => {
  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();
  const faq_items = [
    {
      id: "heading",
      controls: "collapse",
      expanded: true,
      btnClass: "",
      contentClass: "show",
      title: lang.data.Extra.Header.q1,
      details: lang.data.Extra.Header.r1,
    },
    {
      id: "headingOne",
      controls: "collapseOne",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: lang.data.Extra.Header.q2,
      details:lang.data.Extra.Header.r2,
    },
    {
      id: "headingTwo",
      controls: "collapseTwo",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title:lang.data.Extra.Header.q3,
      details:lang.data.Extra.Header.r3,
    },
    {
      id: "headingThree",
      controls: "collapseThree",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: lang.data.Extra.Header.q4,
      details:lang.data.Extra.Header.r4,
    },
    {
      id: "headingFour",
      controls: "collapseFour",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: lang.data.Extra.Header.q5,
      details: lang.data.Extra.Header.r5,
    },
    {
      id: "headingFive",
      controls: "collapseFive",
      expanded: false,
      btnClass: "collapsed",
      contentClass: "",
      title: lang.data.Extra.Header.q6,
      details: lang.data.Extra.Header.r6,
    },
  ];

  return (
    <section id="faq" className="faq-area">
      <div className="container custom-container-four">
        <div className="faq-shape-wrap">
          <img src={shape01} alt="" className="img-one" />
          <img src={shape02} alt="" className="img-two rotateme" />
          {/* <img src={shape03} alt="" className="img-three" /> */}
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="section-title section-title-two text-center mb-60">
              <h2 className="title text-white">
              {lang.data.Extra.Header.n08}</h2>
            </div>

            <div className="faq-wrap wow fadeInUp" data-wow-delay=".2s">
              <div className="accordion" id="accordionExample">
                {faq_items.map((x, index) => (
                  <FaqItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
