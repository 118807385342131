import React, { useEffect, useState } from "react";
import styles from "./PageLoader.module.css";
import Logo from '../../assets/img/logo/logo.png'
import { useLocation } from "react-router-dom";
import useLang from "../../hooks/useLang";

const PageLoader = () => {

  const {
    lang,
  } = useLang();
 
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true); // Establece loading en true cuando cambia la ruta
      setTimeout(() => {
        setLoading(false); // Después de 1 segundo, establece loading en false
      }, 5000); // 1000 milisegundos = 1 segundo
    };
  
    handleRouteChange();
  
    // Limpiar el timeout al desmontar el componente
    return () => clearTimeout();
  }, [pathname]);

  useEffect(() => {
    
    const handleLoad = () => {
      setLoading(false); // Cuando la página ha terminado de cargar completamente, cambiar loading a falso
    };
    // Agregar el event listener cada vez que cambia la ruta
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [loading, pathname]); // Escucha cambios en el estado loading para volver a agregar el event listener


  if (loading) {
    return (
      <div className="logo-box-givartz-loader">
        <div className="container-logo-givartz">
          {/* <img alt="logo-givartz" 
              className="logo-size-givartz"
              src={Logo}
          />  */}
          <p className="text-center text-white mod-cool">PicoAiPicks</p>
          <p className="text-center text-white text-boomba">{lang.data.Extra.Header.n09}...</p>
          <section className="bar-bar-givartz">
            <span></span>
          </section>
        </div>
      </div>
    );
  } else {
    return null; // Ocultar el loader una vez que la carga ha terminado
  }
};

export default PageLoader;
