import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo.png";
import { scrollToTop } from "../../lib/helpers";
import videobg from "../../assets/videos/2.mp4"
import useLang from "../../hooks/useLang";
const FooterOne = () => {
  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();
  return (
    <footer className="background-footer">
        <video 
              className="video-bg-pro"
              autoPlay={true}
            loop={true} 
            playsInline={true}
            muted={true}
            webkitplaysinline="true"
             tabIndex="-1" 
             aria-hidden="true">
              <source  src={videobg} type="video/mp4"/>
                <source  src={videobg} type="video/webm"/>
              <source src={videobg} type="video/ogg"/>

            

              </video>


      <div className=" bg-black-givarts">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-6 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo">
                    <img src={logoImage} alt="BigTech Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                    {lang.data.Footer.Header.n01}
                    
                    </p>
                    <ul className="footer-social">
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li> */}
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      {/* <li>
                        <a href="#">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Pico Ai Picks</h4>
                  <div className="footer-link">
                    <ul>
                     
                      <li>
                        <Link to="/"> {lang.data.Footer.Header.n02}</Link>
                      </li>
                      <li>
                        <Link to="/pricing">{lang.data.Footer.Header.n03}</Link>
                      </li>
                    
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">PicoAi</h4>
                  <div className="footer-link">
                    <ul>
                      {/* <li>
                        <Link to="/">Help Center</Link>
                      </li>
                      <li>
                        <Link to="/">Ai Intro</Link>
                      </li> */}
                        <li>
                        <Link to="/support">{lang.data.Footer.Header.n04}</Link>
                      </li>
                      <li>
                        <Link to="/about">{lang.data.Footer.Header.n05}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>
                 Subscribe to PicoAiPicks. 
                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="contact@PicoAiPicks.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>
                  {lang.data.Footer.Header.n06}
                    </p>
                </div>
              </div>
              {/* <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="#">Terms and conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="#">Login / Signup</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
