import React from "react";
import About from "../../components/About/About";
import Banner from "../../components/Banner/Banner";
import BannerTwo from "../../components/Banner/BannerTwo";
import ContactTwo from "../../components/Contact/ContactTwo";
import CountDownTwo from "../../components/CountDown/CountDownTwo";
import CounterArea from "../../components/CounterArea/CounterArea";
import DownloadArea from "../../components/DownloadArea/DownloadArea";
import Faq from "../../components/Faq/Faq";
import Newsletter from "../../components/Newsletter/Newsletter";
import RoadmapTwo from "../../components/Roadmap/RoadmapTwo";
import TeamTwo from "../../components/Team/TeamTwo";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Testimonios from "../../components/Testimonios/Testimonios";
import WhoWeAre from "../../components/BlogAside/BlogTags";
import LayoutTwo from "../../layouts/LayoutTwo";
import LayoutOne from "../../layouts/LayoutOne";
import LayoutGivartz from "../../layouts/LayoutGivartzService";
import videobg from "../../assets/videos/1.mp4"
import videohead from "../../assets/videos/3.mp4"
import videoblue from "../../assets/videos/4.mp4"
import videobody from "../../assets/videos/bg.mp4"
import logoImage from "../../assets/img/logo/logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";
import JuegosPico from '../../assets/img/images/JuegosPicosGoal.png'
import useLang from "../../hooks/useLang";



const Servicios = () => {
  const {
    lang,
    ActiveEN,
    ActiveES
  } = useLang();
  
  return (
    <LayoutGivartz>
      <main className="fix" >
       {/* <WhoWeAre /> */}
    
       <section className="rene-sensa">
       <section className="video-rene">
        <div className="flex-rene">
          <h1  className="Text-Big">        
          {lang.data.Home.Header.n01}
          </h1>
          <p>
          {lang.data.Home.Header.n02}
          </p>
  </div>
  <video 
  className="video-bg-rene"
  autoPlay={true}
  loop={true} 
  playsInline={true}
  muted={true}
  webkitplaysinline="true"
  tabIndex="-1" 
  aria-hidden="true">
  <source  src={videobg} type="video/mp4"/>
    <source  src={videobg} type="video/webm"/>
  <source src={videobg} type="video/ogg"/>
  </video>
          </section>
       </section>
       <section  className="rene-sensa-04">
        <div className="box-rene-design">
        <h2 className="text-arena-rene">
        {lang.data.Home.Section02.n01}
                          </h2>
                          <h2 className="text-arena-rene ">
                          {lang.data.Home.Section02.n02}
          </h2>
          <div>
          <div className="">       
            <video 
              className="video-bg-brutal"
              autoPlay={true}
            loop={true} 
            playsInline={true}
            muted={true}
            webkitplaysinline="true"
            tabIndex="-1" 
            aria-hidden="true">
              <source  src={videohead} type="video/mp4"/>
                <source  src={videohead} type="video/webm"/>
              <source src={videohead} type="video/ogg"/>
              </video>
            </div>
            </div>
          </div>
        

       </section>

       <section className="rene-sensa-03">

        <div className="box-rene-design">
     

          <div>
          {/* <div className="Box-round-rene">

          
<video 
  className="video-bg-brutal"
  autoPlay={true}
loop={true} 
playsInline={true}
muted={true}
webkitplaysinline="true"
tabIndex="-1" 
aria-hidden="true">
  <source  src={videohead} type="video/mp4"/>
    <source  src={videohead} type="video/webm"/>
  <source src={videohead} type="video/ogg"/>



  </video>
</div> */}

<About

  lang={lang}

/>
          </div>

          <div className="box-arena-rene-hijo">

            {/* <div className="">
              <h3 className="text-arena-rene">PICO Analyzes</h3>

            </div> */}

            

            <div className="d-grid pb-9">
              <h4 className="text-arena-rene">
              {lang.data.Home.Section03.n02}
              </h4>
                <img
                  alt="Juegos"
                  className="text-arena-rene"
                  src={JuegosPico}
                />
            </div>

            

          </div>
        </div>

       </section>

        <section className="rene-sensa-04">

        <div className="box-rene-design">
        <h2 className="text-arena-rene-white ">
        {lang.data.Home.Section04.n01}

          
       
          </h2>

          <div>
          <div className="Box-round-rene d-none">

          
{/* <video 
  className="video-bg-brutal"
  autoPlay={true}
loop={true} 
playsInline={true}
muted={true}
webkitplaysinline="true"
tabIndex="-1" 
aria-hidden="true">
  <source  src={videohead} type="video/mp4"/>
    <source  src={videohead} type="video/webm"/>
  <source src={videohead} type="video/ogg"/>



  </video> */}
 
 
</div>

<div>
   

</div>
          </div>

          <div className="box-arena-rene-hijo d-none">

            <div className="">
              <h3 className="text-arena-rene-black-lil-4-white">Pico Ai Picks
</h3>

            </div>

            

            <div className="">
              <h4 className="text-arena-rene-black-lil-3-white">
              winning rate 69% and up
              </h4>

            </div>

            

          </div>
        </div>

       </section>

       <Testimonios 
       
        lang={lang}
       />

       
      

       <section className="rene-sensa">
       <section className="video-rene-b">
        
<div className="flex-rene-2">
<div className="d-grid pico-logo-box">
<img className="logopico" src={logoImage} alt="" />
</div>
<a 
                      className="btn activao"
                        target="_blank" rel="noopener"
                        href="https://sublaunch.co/picoaipickspro"
                        >
                
              
                Subscribe


              </a> 
  
        
<h2 className="text-simple">
{lang.data.Home.Section05.n01}

                          </h2>
        

          <p className="text-normal">
          {lang.data.Home.Section05.n02}
          </p>

                    

        
  </div>
  

  

<video 
  className="video-bg-rene"
  autoPlay={true}
  loop={true} 
  playsInline={true}
  muted={true}
webkitplaysinline="true"
 tabIndex="-1" 
 aria-hidden="true">
  <source  src={videoblue} type="video/mp4"/>
    <source  src={videoblue} type="video/webm"/>
  <source src={videoblue} type="video/ogg"/>



  </video>





          </section>

       </section>
       <section className="rene-sensa-03t">

       <div className="d-grid  telegram-win telegram-win-box">
              <h4 className="text-arena-rene">
              {lang.data.Home.Section06.n01}
              </h4>
                {/* <img
                  alt="Juegos"
                  className="text-arena-rene"
                  src={JuegosPico}
                /> */}
                <a 
                      className="btn activao"
                        target="_blank" rel="noopener"
                        href="https://telegram.org/"
                        >
                
              
                Download Telegram


              </a> 
            </div>

       </section>
     
        
       {/* <Banner />
        <About /> 
        */}
        {/* <WhyChooseUsTwo /> */}
        {/* <CounterArea /> */}
        {/* <RoadmapTwo />
        <TeamTwo />
        <DownloadArea /> */}
        {/* <Faq /> */}
        {/* <ContactTwo /> */}
        {/* <Newsletter /> */}
      </main>
    </LayoutGivartz>
  );
};

export default Servicios;
